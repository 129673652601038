$font-primary: "Montserrat", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", "Arial",
  "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-secondary: $font-primary;
$font-3: "Milonga", $font-primary;

$color-white: #fff;
$color-whiteDark: #ddd;
$color-greyDark: #1d1d1d;
$color-green: #4caf50;
$color-greenLight: #f4f6e8;
$color-greenDark: #405b0b;

$width-xs: 0;
$width-sm: 540px;
$width-md: 720px;
$width-lg: 960px;
$width-xl: 1140px;
$width-xxl: 1320px;

$bp-xs: 0;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

$bp-sm-1px: 575px;
$bp-md-1px: 767px;
$bp-lg-1px: 991px;
$bp-xl-1px: 1199px;
$bp-xxl-1px: 1399px;
