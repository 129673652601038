@import "./theme";

.products__grid {
  display: grid;
  gap: 1.2em;
  grid-template-columns: 1fr;

  @media (min-width: $bp-sm) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $bp-lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    box-shadow: 0 8px 30px rgba(122, 122, 122, 0.15);

    &__title {
      flex-grow: 2;
      text-decoration: none;
      text-align: center;

      h3 {
        margin: 0;
        padding: 25px 20px;
        line-height: 1.3333em;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
      }
    }

    &__link {
      display: block;
    }
  }
}

.products__grid__item__picture {
  background-color: $color-greenLight;
  margin: 0;
}

.products__grid__item__picture__image {
  background-attachment: scroll;
  background-image: url("/vendors/images/bg_345.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 200px;

  @media (min-width: $bp-sm) {
    min-height: 250px;
  }

  @media (min-width: $bp-md) {
    min-height: 300px;
  }

  @media (min-width: $bp-lg) {
    min-height: 340px;
  }
}

.products__grid__item__picture__text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.products__grid__item__picture__title {
  color: #fff;
  margin: 0 1em;
  padding: 0.4em;
  background: #4caf50;
}

.products__categories {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1.2em;
  margin-bottom: 2rem;

  &__link {
    padding: 0.5em;
    background-color: $color-greenLight;
    border-radius: 5px;
  }
}

.product_collection__back_button {
  display: block;
  font-weight: bold;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  line-height: 1.2em;
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px #0d5425;
  background-image: url("../../vendors/images/the-leaves-in-the-dew-mirror.png");
  width: 50%;
  font-size: 20px;
  padding: 3em 1.3em;

  @media (min-width: $bp-md) {
    font-size: 30px;
    padding: 3rem;
    width: 8em;
    background-size: cover;
  }
}
